import { Box } from '@mui/material'
import React from 'react'
import { FaFacebookSquare, FaInstagram, FaPhoneAlt, FaWhatsappSquare, FaYoutube } from 'react-icons/fa'
import { MdMail, MdOutlineLocationOn } from 'react-icons/md'

export default function SupportScreen() {
    return (
        <Box
        display="flex"
        justifyContent={"center"}
        mt={2}
        >
            <div className="contact-links">
                <h1>contact</h1>
                <p>Fill free to contact with the team, our tech team will reply you as soon as possible.</p>
                <div className="links">
                    <div className="link">
                        <a className="icons-s" href="https://www.facebook.com/saalkathersajaru" target="_" ><FaFacebookSquare /></a>
                    </div>
                    <div className="link">
                        <a className="icons-s" href="https://www.youtube.com/channel/UCMvqVvcl8Yl8qNJBv2kaMuA?sub_confirmation=1" target="_" ><FaYoutube /></a>
                    </div>
                    <div className="link">
                        <a className="icons-s" href="https://www.instagram.com/sks_production.official/?hl=en" target="_" ><FaInstagram /></a>
                    </div>
                    <div className="link">
                        <a className="icons-s" href="https://wa.link/vbqy6a" target="_" ><FaWhatsappSquare /></a>
                    </div>

                </div>
                <div className="address">
                    <a href="tel:9734182775"><p><FaPhoneAlt /> +91 9734182775</p></a>
                    <a href="/" target="_"><p><MdMail /> salkathersojaru@gmail.com</p></a>
                    <p><MdOutlineLocationOn />Ranibandh, Bnakura, West Bnegal</p>
                </div>
            </div>
            <style>
                {`
                .contact-us{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#contact {
  background: linear-gradient(177.7deg, #C4C4C4 -258.66%, rgba(196, 196, 196, 0) 98.07%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.2rem;
}

.contact-box {
  width: clamp(100px, 90%, 1000px);
  margin: 80px 50px;
  display: flex;
  flex-wrap: wrap;
}

.contact-links, .contact-form-wrapper {
  width: 50%;
  padding: 8% 5% 10% 5%;
}


.contact-links {
  background-color: #1ba577;
  background:
    
    /* radial-gradient(
      circle at 0% 0%, #575c5a 0 40%, transparent 40.2%
    ),  */
    rgb(56, 56, 56);
  border-radius: 20px;
  text-align: center;
}

.contact-form-wrapper {
  background-color: #0e0e0e8f;
  border-radius: 0 10px 10px 0;
}

@media only screen and (max-width: 800px) {
  .contact-links, .contact-form-wrapper {
    width: 100%;
  }
  
  .contact-links {
    border-radius: 10px 10px 0 0;
  }
  
  .contact-form-wrapper {
    border-radius: 0 0 10px 10px;
  }
}

@media only screen and (max-width: 400px) {
  .contact-box {
    width: 95%;
    margin: 8% 5%;
  }
}

.contact-links h1 {
  font-size: clamp(30px, 6vw, 60px);
  letter-spacing: 2px;
  transform: scale(.95, 1);
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 50px;
}

.link {
  margin: 10px;
  cursor: pointer;
}

.contact-us a{
  font-size: 2rem;
  transition: 0.2s;
  user-select: none;
  color: var(--secondery-color);

}
.links a{
  transition: all 400ms ease;
}

.links a:hover {
  transform: scale(1.1);
  color: white;
  transition: all 400ms ease;
}

.links a:active {
  transform: scale(1.1, 1.1);
}
                `}
            </style>
        </Box>
    )
}
