





const team = [
    "https://firebasestorage.googleapis.com/v0/b/sks-play-md-contact-form.appspot.com/o/Team%2Fimg1.jpg?alt=media&token=8d946d96-d415-4990-aa75-7294990aa50e",

    "https://firebasestorage.googleapis.com/v0/b/sks-play-md-contact-form.appspot.com/o/Team%2Fimg2.jpg?alt=media&token=c0ec667f-69da-4371-8862-b08e6205b7e7",

    "https://firebasestorage.googleapis.com/v0/b/sks-play-md-contact-form.appspot.com/o/Team%2Fimg3.jpg?alt=media&token=3820f76e-14ce-4960-b5d2-0f6df1d453b1",

    "https://firebasestorage.googleapis.com/v0/b/sks-play-md-contact-form.appspot.com/o/Team%2Fimg4.JPG?alt=media&token=c38808aa-8643-4d0d-900d-07434179b495",

    "https://firebasestorage.googleapis.com/v0/b/sks-play-md-contact-form.appspot.com/o/Team%2Fimg5.jpeg?alt=media&token=0a32ed50-2d1a-456c-9f85-d89417455ab7",

    "https://firebasestorage.googleapis.com/v0/b/sks-play-md-contact-form.appspot.com/o/Team%2Fimg6.jpg?alt=media&token=7fbf1751-620a-455e-bb14-b313c157cf41",

    "https://firebasestorage.googleapis.com/v0/b/sks-play-md-contact-form.appspot.com/o/Team%2Fimg7.jpg?alt=media&token=dd73a879-569f-4ad5-b0d0-b1317c2decdc",


    "https://firebasestorage.googleapis.com/v0/b/sks-play-md-contact-form.appspot.com/o/Team%2Fimg8.jpg?alt=media&token=84178b3e-aea6-43a4-99ad-3ffc3352b849",


    "https://firebasestorage.googleapis.com/v0/b/sks-play-md-contact-form.appspot.com/o/Team%2Fimg9.JPG?alt=media&token=2da027ed-a2b5-4b1a-b5cf-fcf723d895a8",


    "https://firebasestorage.googleapis.com/v0/b/sks-play-md-contact-form.appspot.com/o/Team%2Fimg12.jpg?alt=media&token=e252bf3e-c648-430e-9520-06184022ec01",
    

    "https://firebasestorage.googleapis.com/v0/b/sks-play-md-contact-form.appspot.com/o/Team%2Fimg11.jpg?alt=media&token=3dc47478-a9c2-4180-b974-11e64e7f03ea",
]

export default team;