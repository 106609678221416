import { Avatar, Badge, Box, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import TMenu from '../common/TMenu'
import { LogoutOutlined, NotificationsOutlined, PersonOutlined, SettingsOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const styles = {
    avater: {
        backgroundColor: "primary.main",
        width: 30,
        height: 30,
    },
    card : {
        // backgroundColor : "#eff3ff",
        padding : "5px",
        borderRadius : "10px",
        border : "1px solid hsl(210, 14%, 87%)"
    }
}


export default function UserCard() {
    const nav = useNavigate();
    const userInfo = localStorage.getItem("userName")

    const handleLogout = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        localStorage.removeItem("userName")
        nav("/")
    }
    const MENU_ITEMS = [
        {
            label: 'Logout',
            onClick: handleLogout,
            icon: <LogoutOutlined />
        },
        {
            label: 'Profile',
            onClick: () => { },
            icon: <PersonOutlined />
        }
    ]


    return (
        <Box display="flex" gap={4}>
            <IconButton>
                <Badge color="error" overlap="circular" variant="dot">
                    <NotificationsOutlined />
                </Badge>
            </IconButton>
            <Stack spacing={1} direction={"row"} alignItems={"center"} sx={styles.card} >
                <Typography variant='subtitle2'>{userInfo}</Typography>
            <TMenu
                buttonIcon={<Avatar alt="Remy Sharp" sx={styles.avater}>
                    {userInfo ? userInfo[0] : 'A'}
                </Avatar>}
                buttonProps={{ p: 0 }}
                menuItems={MENU_ITEMS}
                />
            </Stack>
        </Box>
    )
}


