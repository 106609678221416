import { Box, Chip, Typography } from '@mui/material'
import React from 'react'

export default function ViewFiled({field,value,...props}) {
  return (
    <Box 
    display="flex"
    flexDirection={"column"}
    >
        <Typography variant="caption" sx={{color : "text.secondary"}}>{field}</Typography> {" "}
        <Typography variant="cardText1" color="initial" sx={{color : "text.primary", lineHeight : "1.17"}}>{value}</Typography>
    </Box>
  )
}
