import "./App.css";
import Distributions from "./components/Distributions/Distributions";
import Landing from "./components/Home/Landing";
import Navbar from "./components/Navbar/Navbar";
import Pricing from "./components/Pricing/Pricing";
import Promotion from "./components/Promotion/Promotion";
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import About from "./components/About/About";
// import Popup from "./components/ad/Popup";
import SendDemo from "./components/SendDemo/SendDemo";
import Longin from "./components/auth/Longin";
import Register from "./components/auth/Register";
import ScrollToTop from "./components/ScrollToTop";
import { useState } from "react";
import Layout from "./components/dashboard/core/Layout";
import { ToastContainer } from "react-toastify";
import DashboardScreen from "./components/dashboard/screens/DashboardScreen.jsx";
import AlbumScreen from "./components/dashboard/screens/AlbumScreen.jsx";
import UploadScreen from "./components/dashboard/screens/UploadScreen.jsx";
import SupportScreen from "./components/dashboard/screens/SupportScreen.jsx";
import ProtectedRoute from "./components/dashboard/common/ProtectedRoute.js";
import AuthGuard from "./components/dashboard/common/AuthGuard.js";
function App() {
  const [loading, setLoding] = useState(false);
  const loader = document.getElementById('loader')
  if (loader) {
    setTimeout(() => {
      loader.style.display = "none";
      setLoding(true);
    }, 0)
  }
  return (
    loading && (<>
      <Router>
        <ScrollToTop>
          <ConditionalNav />
          {/* <Popup /> */}
          <Routes>
            <Route path="/" element={<AuthGuard><Landing title="Saal Kather Sajaru" /></AuthGuard>} />
            <Route path="/sks-play-md" element={<Landing title="Saal Kather Sajaru" />} />
            <Route path="/distribution" element={<Distributions />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/promotion" element={<Promotion />} />
            <Route path="/about" element={<About />} />
            <Route path="/senddemo" element={<SendDemo />} />
            <Route path="/login" element={<AuthGuard><Longin /></AuthGuard>} />
            <Route path="/register" element={<Register />} />

            <Route path="/app" element={<ProtectedRoute allowedRoles={["user"]}><Layout /></ProtectedRoute>} >
              <Route index element={<ProtectedRoute allowedRoles={["user"]}><DashboardScreen /></ProtectedRoute>} />
              <Route path="album" element={<ProtectedRoute allowedRoles={["user"]}><AlbumScreen /></ProtectedRoute>} />
              <Route path="upload" element={<ProtectedRoute allowedRoles={["user"]}><UploadScreen /></ProtectedRoute>} />
              <Route path="reports" element={<ProtectedRoute allowedRoles={["user"]}>report</ProtectedRoute>} />
              <Route path="analytics" element={<AlbumScreen />} />
              <Route path="creadit_history" element={<AlbumScreen />} />
              <Route path="support" element={<ProtectedRoute allowedRoles={["user"]}><SupportScreen /></ProtectedRoute>} />
            </Route>
          </Routes>
          <ConditionalFooter />
        </ScrollToTop>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='dark'
        />
      </Router>
    </>)
  );
}


function ConditionalFooter() {
  const location = useLocation();
  const publicRoutes = ["/", "/sks-play-md", "/distribution", "/pricing", "/promotion", "/about", "/senddemo", "/login", "/register"];

  // Render the footer only if the current path is in public routes
  return publicRoutes.includes(location.pathname) ? <Footer /> : null;
}

function ConditionalNav() {
  const location = useLocation();
  const publicRoutes = ["/", "/sks-play-md", "/distribution", "/pricing", "/promotion", "/about", "/senddemo", "/login", "/register"];

  // Render the footer only if the current path is in public routes
  return publicRoutes.includes(location.pathname) ? <Navbar /> : null;
}

export default App;
