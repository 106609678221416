import { Alert, Box, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import image from "../../../../asset/ad/sks-play-md-4.jpg"
import ViewFiled from '../../common/ViewFiled'
import axios from 'axios'

export default function SongModalView({data}) {
    const [songImage,setSongImage] = useState({});
    useEffect(()=>{
        (async()=>{
            const res = await axios({
                url : `https://api.bengalpictures.com/api/user/getSongDetais/${data.albumId}`,
                method : "GET"
            })
            setSongImage(res?.data)
        })()
    },[])

  return (
    <Box p={2} sx={{backgroundColor: '#212529',borderRadius: '0 0 20px 20px',}}>
        <Stack direction={"row"} spacing={2}>
            <Stack width={"50%"}>
                    <img src={songImage.url || image} alt="" width={"100%"} height={"auto"} style={{borderRadius : "20px"}} />
            </Stack>
            <Stack width={"50%"} justifyContent={"center"}>
                    <Typography variant="h5"   fontWeight={600}>{data?.song_title}</Typography>
                    <Typography variant="caption" sx={{color : "#C9C9C9", mb: 1}} >Sung by {data?.singer}, Compose by {data?.composer} & Written by {data?.lyricist}</Typography>
                    <Alert severity="info" >
                    <b>Description : </b> {data.song_desc || "No Description Available"}
                    </Alert>
            </Stack>
        </Stack>
        <Grid container spacing={2} mt={1} >
            <Grid item xs={12} sm={6} >
                <ViewFiled field={"Gener"} value={data.gener} />
            </Grid>
            <Grid item xs={12} sm={6} >
                <ViewFiled field={"Sub Gener"} value={data.sub_gener} />
            </Grid>
            <Grid item xs={12} sm={6} >
                <ViewFiled field={"Language"} value={data.language} />
            </Grid>
            <Grid item xs={12} sm={6} >
                <ViewFiled field={"Mood"} value={data.mood} />
            </Grid>
            <Grid item xs={12} sm={6} >
                <ViewFiled field={"Song Type"} value={data.song_type} />
            </Grid>
            <Grid item xs={12} sm={6} >
                <ViewFiled field={"CRBT Start"} value={data.crbt_start} />
            </Grid>
            {data?.director && <Grid item xs={12} sm={6} >
                <ViewFiled field={"Director"} value={data.director} />
            </Grid>}
            {data.star_cast && <Grid item xs={12} sm={6} >
                <ViewFiled field={"Star Cast"} value={data.star_cast} />
            </Grid>}
            {data.isrc && <Grid item xs={12} sm={6} >
                <ViewFiled field={"ISRC"} value={data.isrc} />
            </Grid>}
            {data.trackDuration && <Grid item xs={12} sm={6} >
                <ViewFiled field={"Track Duration"} value={data.trackDuration} />
            </Grid>}
        </Grid>
    </Box>
  )
}
