import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'

export default function SongItemCard({
    field,
    value,
    icon
}) {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "linear-gradient(115.11deg, rgba(37, 37, 37, 0.275) 38.12%, rgba(139, 139, 139, 0.5) 195.25%)",
            border: "1px solid #4A4A4A",
            borderRadius: "20px",
            minWidth: 300,
            p:2,
            gap : 2,
            cursor : 'pointer'
        }}>
            <Box  >
                <Avatar sx={{ 
                    background: 'linear-gradient(284.71deg,rgb(94, 132, 228) 4.75%,rgb(203, 78, 190) 70.9%)',
                    width : 50,
                    height : 50
                    }} >
                    
                    {icon}
                </Avatar>
            </Box>
            <Stack direction={"column"} >
                <Typography variant='cardText2' >{field}</Typography>
                <Typography variant='cardText1' >{value}</Typography>
            </Stack>
        </Box>
    )
}
