import React, { useEffect, useState } from "react";
import "./Navbar.css"
import { NavLink, Link } from "react-router-dom";
import logo from "../../asset/logo_main.png"
import Navsm from "./Navsm";
import { useNavigate } from "react-router-dom";

export default function Navbar() {

  const [user, setUser] = useState("")
  const [showUserpanel, setShowUserpanel] = useState(false)
  const nav = useNavigate();
  const userName = localStorage.getItem("userName")
  useEffect(() => {
    setUser(userName)
  }, [])

  const handleUser = () => {
    setShowUserpanel(!showUserpanel)
  }
  const handleLogout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("userName")
    window.location.href = "/";
  }
  return (
    <>
      <div>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand" href="#home">
              <img src={logo} alt="" width={120} className="d-inline-block align-text-top" />
            </a>
            <span className="beta">Beta Version</span>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              {
                !user ? (
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink className="nav-link active" aria-current="page" to='/' style={({ isActive }) => { return { color: isActive ? 'rgba(47, 203, 110, 1)' : 'white' } }}>
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to='/distribution' style={({ isActive }) => { return { color: isActive ? 'rgba(47, 203, 110, 1)' : 'white' } }}>
                      Distributions
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to='/pricing#pricing' style={({ isActive }) => { return { color: isActive ? 'rgba(47, 203, 110, 1)' : 'white' } }}>
                      Pricing
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/promotion" style={({ isActive }) => { return { color: isActive ? 'rgba(47, 203, 110, 1)' : 'white' } }}>
                      Promotion
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/about" style={({ isActive }) => { return { color: isActive ? 'rgba(47, 203, 110, 1)' : 'white' } }}>
                      About & Contact
                    </NavLink>
                  </li>
  
                  <li className="nav-item">
                    <button className="sks-btn" onClick={() => {
                      nav("/login")
                    }} >
                      Login
                    </button>
                  </li>
                  
                </ul>
                ) : (
                  <div className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <span className="logged_user text-light">Hey, <span className="username">{user}</span></span>
                  </div>
                )
              }
             
            </div>
          </div>
        </nav>
      </div>
      <Navsm />
    </>
  );
}
