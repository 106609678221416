import React, { useState } from "react";
import { 
  BottomNavigation, 
  BottomNavigationAction, 
  Box, List, ListItem, 
  ListItemButton, ListItemIcon, 
  ListItemText, useMediaQuery 
} from "@mui/material";
import SpaceDashboardRoundedIcon from '@mui/icons-material/SpaceDashboardRounded';
import DonutSmallRoundedIcon from '@mui/icons-material/DonutSmallRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AlbumRoundedIcon from '@mui/icons-material/AlbumRounded';
import { Link, useLocation } from "react-router-dom";
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
// Map icons to the JSON keys
const iconMapping = {
  Dashboard: <SpaceDashboardRoundedIcon />,
  Album: <AlbumRoundedIcon />,
  Reports: <DescriptionRoundedIcon />,
  Analytics: <DonutSmallRoundedIcon />,
  CreditHistory: <AccountBalanceWalletRoundedIcon />,
  Support: <SupportAgentRoundedIcon />,
};

export default function Sidebar() {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)"); // Check if the screen is small
  const [value, setValue] = useState(location.pathname); 
  const menuItems = [
    { icon: "Dashboard", path: "/app", labelName: "Dashboard" },
    { icon: "Album", path: "/app/album", labelName: "Album" },
    // { icon: "Reports", path: "/app/reports", labelName: "Reports" },
    // { icon: "Analytics", path: "/app/analytics", labelName: "Analytics" },
    // { icon: "CreditHistory", path: "/app/creadit_history", labelName: "Wallet" },
    { icon: "Support", path: "/app/support", labelName: "Support" },
  ];


  if (isMobile) {
    return (
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
        }}
      >
        {menuItems.map((item, index) => (
          <BottomNavigationAction
            key={index}
            label={item.labelName}
            icon={iconMapping[item.icon]}
            component={Link}
            to={item.path}
            value={item.path}
            sx={{
              "&.Mui-selected": { color: "primary.main" },
            }}
          />
        ))}
      </BottomNavigation>
    );
  }

  return (
    <Box
      sx={{
        width: 250,
        height: "100vh",
        backgroundColor: "background.paper",
        borderRight: "1px solid",
        borderColor: "divider",
        display: "flex",
        flexDirection: "column",
        paddingTop : "70px"
      }}
    >
      {/* Sidebar Menu */}
      <List>
        {menuItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton 
              component={Link}
              to={item.path}
              sx={{
                backgroundColor: location.pathname === item.path ? "#37373E" : "transparent",
                "&:hover": { backgroundColor: "#2E2E2E" },
                borderRadius : "20px",
                margin : "7px 10px 7px 10px"
              }}>
              <ListItemIcon sx={{color : "primary.main"}}>{iconMapping[item.icon]}</ListItemIcon>
              <ListItemText sx={{color : "white.main",fontWeight : 600}} primary={item.labelName} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
