import React from 'react'
import ScreenToolbar from '../common/ScreenToolbar'
import { Typography } from '@mui/material'
import UploadForm from '../comp/album/UploadForm'

export default function UploadScreen() {
  return (
    <div>
      <ScreenToolbar
        leftComps={<Typography variant="title" >Submit Your Album</Typography>}
      />
      <UploadForm/>
    </div>
  )
}
