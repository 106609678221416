import * as React from 'react';
import {
    Button,
    IconButton,
    Typography,
    Modal,
    Box,
    Stack
} from '@mui/material';
import { Close } from '@mui/icons-material';


export default function AppModal(props) {
    const { open, setOpen, onClose, overflow = false } = props;
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (onClose) {
            onClose();
        }else{
            setOpen(false);
        }
    };


    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                // BackdropProps={{
                //     sx: {
                //       backdropFilter: "blur(5px)", // Apply blur effect
                //       backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: Add a semi-transparent background
                //     },
                //   }}
            >
                <Box sx={{ ...styles.container, width: props?.width }}>
                    <Box sx={styles.header}>
                        <Typography id="title" variant='title' component={"div"} sx={styles.headerText}>
                            {props?.modalTitle}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Stack sx={{...styles.body, overflowY : overflow ? 'auto' : 'none', scrollPaddingLeft: '10px'}} >
                        {props?.children}
                    </Stack>
                    
                </Box>
            </Modal>
        </div>
    );
}



const styles = {
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        border: 'none !important',
        borderRadius: '20px',
        boxShadow: 24,
        outline: 'none',
        // maxHeight: '80%'
    },
    header: {
        padding: '5px 20px',
        backgroundColor: 'rgba(47, 203, 110, 1)',
        borderRadius: '20px 20px 0px 0px !important',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color : "white.main"
    },
    headerText: {
        fontSize : '16px'
    },
    body:{
        maxHeight : 'calc(100vh - 200px)',
        overflowX  : 'hidden'
    }

};