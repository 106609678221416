import { Avatar, Box, IconButton, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react';
import headerImg from "../../../asset/dashboardHeader.png"
import grediendImg from "../../../asset/Home/headcolor.png"
import AppCard from '../common/Card';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
export default function DashboardScreen() {
  const nav = useNavigate();

  const [dashboard,setDashboard] = useState({});
    useEffect(()=>{
        (async()=>{
            const res = await axios({
                url : `${process.env.REACT_APP_API_BASE}/card_details`,
                method : "GET",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            setDashboard(res?.data?.count)
        })()
    },[])

  return (
    <Box>
      <Box sx={styles.headerCard}>
        <img src={headerImg} alt="" width={"100%"} height={"auto"} />
      </Box>
      <Box mt={3} sx={styles.cardContainer}>
        <Stack spacing={3} direction={{sm : "row" , xs : "column"}} justifyContent={"center"}>
          <AppCard
            icon={<SavingsRoundedIcon sx={{ fontSize: 70 }} />}
            field="Total Earning"
            value="₹ 0"
          />
          <AppCard
            icon={<CurrencyRupeeRoundedIcon sx={{ fontSize: 70 }} />}
            field="Payment Release"
            value="₹ 0"
          />
          <AppCard
            icon={<PlayArrowRoundedIcon sx={{ fontSize: 70 }} />}
            field="Total Album"
            value={dashboard.albumCount || 0}
          />
        </Stack>
        <img src={grediendImg} alt="" style={styles.image} />
      </Box>
      <Box sx={styles.playButtonRoot}>
        <IconButton onClick={()=>{nav("/app/upload")}}>
          <Avatar sx={{
            background: 'linear-gradient(284.71deg, #A5E45E 4.75%, #4ECB71 70.9%)',
            width: 100,
            height: 100,
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
          }} >
            <FileUploadRoundedIcon sx={{ fontSize: 70, color: "white.main" }} />
          </Avatar>
        </IconButton>
      </Box>
    </Box>
  )
}


const styles = {
  headerCard: {
    background: 'linear-gradient(284.71deg, #A5E45E 4.75%, #4ECB71 70.9%)',
    width: "100%",
    p: 2,
    mt: 1,
    borderRadius: "20px"
  },
  cardContainer: {
    position: "relative",
    pb: "150px",
    overflow: "hidden",
    borderRadius: "0px 0px 20px 20px"
  },
  image: {
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  playButtonRoot: {
    display: "flex",
    justifyContent: "center",
    mt: '-55px'
  }
}