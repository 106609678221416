import React from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";

const steps = ["Album Details", "Song Details", "Finished"];

export default function ControlledStepper({ activeStep }) {
  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
