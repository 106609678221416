import React from 'react'
import './Login.css'
import { useFormik } from "formik";
import * as yup from "yup";
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Longin() {

  const nav = useNavigate();

  const validationSchema = yup.object({
    userName: yup.string().required("Required Feild"),
    password: yup.string().required("Required Feild"),
  });

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: ""
    },
    validationSchema: validationSchema,
    onSubmit: (value) => {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE}/auth/login`,
        data: value
      }).then((resp) => {
        console.log(resp)
        if (resp.status === 200) {
          localStorage.setItem("token", resp?.data?.token);
          localStorage.setItem("userName", resp?.data?.user?.userName);
          localStorage.setItem("user",JSON.stringify(resp?.data?.user))
          if(resp?.data?.user.role === 'user'){
            nav("/app")
          }else{
            nav("/app/admin")
          }
        } else {
          toast.error("Unable To Login")
        }
      }).catch((err) => {
        console.log(err)
        toast.error("Somthing went wrong")
      })
    }
  })

  const handleSignUp = () => {
    nav("/register")
  }
  return (
    <div>
      <div className="login_content">
        <div className="left">
          <h1>Login To Your Account</h1>
          <div className="form_box" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}>
            {formik.errors.userName && formik.touched.userName ? (
              <div style={{ color: "red" }}>
                {formik.errors.userName}
              </div>
            ) : null}
            <input value={formik.values.userName} onChange={formik.handleChange} name="userName" id="userName" placeholder='Username' />
            {formik.errors.password && formik.touched.password ? (
              <div style={{ color: "red" }}>
                {formik.errors.password}
              </div>
            ) : null}
            <input type="password" value={formik.values.password} onChange={formik.handleChange} name="password" id="password" placeholder='Password' />
            <button className="sks-btn" type='button' onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}>Login</button>
          </div>
        </div>
        <div className="right">
          <h2>New Here ?</h2>
          <p>Sign up and spred your music worlwide through Saal Kather Sajaru Music Distribution</p>
          <button onClick={handleSignUp} className="sks-btn-black">Sign Up</button>
        </div>
      </div>
    </div>
  )
}

export default Longin
