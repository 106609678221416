import React from "react";
import { useFormik } from "formik";
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded';
import * as yup from "yup";
import { useState } from "react";
import "./Upload.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Box,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
} from "@mui/material";
import ControlledStepper from "../../common/Stepper";
import SongItemCard from "./SongItemCard";

function UploadForm() {
  const nav = useNavigate();

  const [content, setContent] = useState();
  const [meta, setMeta] = useState([]);
  const [final, setFinal] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [active, setActive] = useState(false);
  const [load, setLoad] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [progress, setProgress] = useState(null);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const scrollTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const validationMeta = yup.object({
    title: yup.string().required("Required Feild"),
    content_type: yup.string().required("Required Feild"),
    album_art: yup.mixed().required("Album Art Is Mandetory Feild")
      .test("type", "Only the JPEG format with 3000 x 3000 pixel is accepted", (value) => {
        return value && (
          value.type === "image/jpeg" ||
          value.type === "image/jpg"
        );
      })

  });

  const validationFinal = yup.object({
    song_title: yup.string().required("Required Feild"),
    song_type: yup.string().required("Required Feild"),
    language: yup.string().required("Required Feild"),
    gener: yup.string().required("Required Feild"),
    sub_gener: yup.string().required("Required Feild"),
    mood: yup.string().required("Required Feild"),
    singer: yup.string().required("Required Feild"),
    composer: yup.string().required("Required Feild"),
    lyricist: yup.string().required("Required Feild"),
    wav_file: yup.mixed()
      .required("Wav File Is Mandetory Feild")
      .test("type", "Only .wav file format is accepted", (value) => {
        return value && (
          value.type === "audio/wav"
        );
      }),
    noc_doc: yup.mixed()
      .test("type", "Only the following formats are accepted: .pdf, .doc and .jpeg,", (value) => {

        return value && (
          value.type === "image/jpeg" ||
          value.type === "application/pdf" ||
          value.type === "application/doc" ||
          value.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"

        );
      }),
    crbt_name: yup.string().required("Required Feild"),
    crbt_start: yup.string().required("Required Feild").test("type", "Follow The Specified Formate (HH:MM:SS)", (value) => {
      let format = /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/
      return value && (
        value.match(format)
      );
    }),
  });

  const handleToggle = () => {
    setToggle((toggle) => !toggle);
  };

  let next = toggle ? "active show" : null;
  let prev = !toggle ? "active show" : null;

  const metaFormik = useFormik({
    initialValues: {
      title: "",
      release_date: "",
      live_date: "",
      film_banner: "",
      film_producer: "",
      album_art: "",
      content_type: "",
    },
    validationSchema: validationMeta,
    onSubmit: (values) => {
      setMeta(values);
      handleNext();
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      song_title: "",
      song_type: "",
      language: "",
      song_desc: "",
      gener: "",
      sub_gener: "",
      mood: "",
      singer: "",
      composer: "",
      director: "",
      star_cast: "",
      explicit: "no",
      lyricist: "",
      wav_file: "",
      noc_doc: "",
      crbt_start: "",
      crbt_name: meta.title !== "" ? meta.title : "",
    },
    validationSchema: validationFinal,
    onSubmit: (value) => {

      setFinal((prevData) => [...prevData, value]);
      //have to enable final sumbit
      setActive(true)
      formik.resetForm();
      toast.success("Saved");
      handleNext();
    },
  });

  function UploadSong(albumId) {

    final.forEach((song) => {
      var finalFormData = new FormData();
      finalFormData.append("song_title", song?.song_title);
      finalFormData.append("song_type", song?.song_type);
      finalFormData.append("language", song?.language);
      finalFormData.append("song_desc", song?.song_desc);
      finalFormData.append("gener", song?.gener);
      finalFormData.append("sub_gener", song?.sub_gener);
      finalFormData.append("mood", song?.mood);
      finalFormData.append("singer", song?.singer);
      finalFormData.append("composer", song?.composer);
      finalFormData.append("director", song?.director);
      finalFormData.append("star_cast", song?.star_cast);
      finalFormData.append("explicit", song?.explicit);
      finalFormData.append("lyricist", song?.lyricist);
      finalFormData.append("wav_file", song?.wav_file);
      finalFormData.append("noc_doc", song?.noc_doc);
      finalFormData.append("crbt_start", song?.crbt_start);
      finalFormData.append("crbt_name", song?.crbt_name);
      finalFormData.append("albumId", albumId);
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BP_BASE_URL}/api/user/addDoc`,
        data: finalFormData,
        onUploadProgress: (data) => {
          setProgress(Math.round((data.loaded / data.total) * 100))
        }
      })
        .then(function (response) {
          //handle success
          setLoad(false)

          if (response.status === 200) {
            nav("/app/album");
            toast.success(
              "Form has been submitted for " +
              response.data.songDetails.song_title
            );
          } else {
            toast("Somthing went wrong, please try again");
          }
        })
        .catch(function (error) {
          //handle error
          setLoad(false)

          toast("Somthing went wrong, please try again");
        });
    });

  }

  const handleFinalSubmit = async () => {
    if (activeStep > 0) {
      setLoad(true)
      setBtnDisabled(true)
      var metaFormData = new FormData();
      metaFormData.append("title", meta?.title);
      metaFormData.append("release_date", meta?.release_date);
      metaFormData.append("live_date", meta?.live_date);
      metaFormData.append("film_banner", meta?.film_banner);
      metaFormData.append("film_producer", meta?.film_producer);
      metaFormData.append("album_art", meta?.album_art);
      metaFormData.append("content_type", meta?.content_type);
      metaFormData.append("labelStatus", "LABEL_PENDING");

      const token = localStorage.getItem("token");
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BP_BASE_URL}/api/user/createAlbum`,
        data: metaFormData,
        headers: {
          'api-key': process.env.REACT_APP_BP_AIP_KEY,
          'user-id' : process.env.REACT_APP_LABEL_ID
        },
      })
        .then(async function (response) {
          //handle success
          console.log(response)
          if (response.status === 200) {
            let albumIdResp = response?.data?.albumId;
            
            const saveToSks = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_API_BASE}/submitted_album/${albumIdResp}`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            console.log("saveToSks",saveToSks);
            UploadSong(albumIdResp);
          }
        })
        .catch(function (error) {
          //handle error
          console.log("ERROR === ", error);
          toast.error("Something Went Wrong")
        });
    } else {
      toast("Save Your Song");
    }
  }

  return (
    <div>
      {load && (
        <div className="mid_loader_user text-light flex-column">
          <CircularProgress />
          <div className="progress mb-3 mt-2" style={{ width: '300px' }}>
            <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
          </div>

          <p>Please Wait While Uploading Your Documents</p>
          <small>Don't close the window</small>
        </div>
      )}

      {/* stepper */}

      <ControlledStepper activeStep={activeStep} />

      <form action="">
        <div className="tab-content" id="nav-tabContent">
          {
            activeStep === 0 && (
              <Card
                sx={{
                  maxWidth: 600,
                  mx: "auto",
                  p: 3,
                  boxShadow: 3,
                  borderRadius: 2,
                  // backgroundColor: "background.dark",
                }}
              >
                <CardHeader
                  title={
                    <Typography variant="h6" gutterBottom>
                      Form Details
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Album Title"
                        name="title"
                        value={metaFormik.values.title}
                        onChange={metaFormik.handleChange}
                        error={metaFormik.touched.title && Boolean(metaFormik.errors.title)}
                        helperText={metaFormik.touched.title && metaFormik.errors.title}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Release Date"
                        type="date"
                        name="release_date"
                        value={metaFormik.values.release_date}
                        onChange={metaFormik.handleChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Live Date"
                        type="date"
                        name="live_date"
                        value={metaFormik.values.live_date}
                        onChange={metaFormik.handleChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        error={
                          metaFormik.touched.content_type &&
                          Boolean(metaFormik.errors.content_type)
                        }
                      >
                        <InputLabel id="content-type-label">Content Type</InputLabel>
                        <Select
                          labelId="content-type-label"
                          id="content_type"
                          name="content_type"
                          label="Content Type"
                          value={metaFormik.values.content_type}
                          onChange={(e) => {
                            setContent(e.target.value);
                            metaFormik.handleChange(e);
                          }}
                          required
                        >
                          <MenuItem value="">
                            <em>--Select One--</em>
                          </MenuItem>
                          <MenuItem value="album">Album</MenuItem>
                          <MenuItem value="film">Film</MenuItem>
                        </Select>
                        <FormHelperText>
                          {metaFormik.touched.content_type && metaFormik.errors.content_type}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {content === "film" && (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Film Banner"
                            name="film_banner"
                            value={metaFormik.values.film_banner}
                            onChange={metaFormik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Film Producer"
                            name="film_producer"
                            value={metaFormik.values.film_producer}
                            onChange={metaFormik.handleChange}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Upload Album Art"
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                          metaFormik.setFieldValue("album_art", e.target.files[0])
                        }
                        error={
                          metaFormik.touched.album_art && Boolean(metaFormik.errors.album_art)
                        }
                        helperText={
                          metaFormik.touched.album_art && metaFormik.errors.album_art
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "right" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          metaFormik.handleSubmit();
                        }}
                      >
                        Next
                      </Button>
                    </Grid>

                  </Grid>
                </CardContent>

              </Card>

            )
          }
          {
            activeStep === 1 && (<>
              <Card
                sx={{
                  maxWidth: 600,
                  mx: "auto",
                  p: 3,
                  boxShadow: 3,
                  borderRadius: 2,
                  // backgroundColor: "background.dark",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Song Title"
                        required
                        fullWidth
                        id="song_title"
                        name="song_title"
                        variant="outlined"
                        size="small"
                        value={formik.values.song_title}
                        onChange={formik.handleChange}
                        error={formik.touched.song_title && Boolean(formik.errors.song_title)}
                        helperText={formik.touched.song_title && formik.errors.song_title}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth size="small" variant="outlined">
                        <InputLabel required>Song Type</InputLabel>
                        <Select
                          id="song_type"
                          name="song_type"
                          value={formik.values.song_type}
                          onChange={formik.handleChange}
                          error={formik.touched.song_type && Boolean(formik.errors.song_type)}
                          label="Song Type"
                        >
                          <MenuItem value="">--Select One--</MenuItem>
                          <MenuItem value="Original">Original</MenuItem>
                          <MenuItem value="Cover">Cover</MenuItem>
                          <MenuItem value="Remix">Remix</MenuItem>
                          <MenuItem value="Medley">Medley</MenuItem>
                          <MenuItem value="Live">Live</MenuItem>
                          <MenuItem value="Karaoke">Karaoke</MenuItem>
                          <MenuItem value="DJ">DJ</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth size="small" variant="outlined">
                        <InputLabel required>Language</InputLabel>
                        <Select
                          id="language"
                          name="language"
                          value={formik.values.language}
                          onChange={formik.handleChange}
                          error={formik.touched.language && Boolean(formik.errors.language)}
                          label="Language"
                        >
                          <MenuItem value="">--Select One--</MenuItem>
                          {[
                            "Adibasi", "Awadhi", "Assamese", "Bengali", "Banjara", "Bhojpuri",
                            "Chhattisgarhi", "Dogri", "English", "Garhwali", "Gujarati",
                            "Haryanvi", "Hindi", "Himachali", "Instrumental", "Japanese",
                            "Kannada", "Kashmiri", "Khasi", "Kokborok", "Konkani", "Kumauni",
                            "Malayalam", "Mandarin", "Marathi", "Marwari", "Maithili",
                            "Manipuri", "Naga", "Nagpuri", "Nepali", "Odia", "Oriya", "Pali",
                            "Persian", "Punjabi", "Rajasthani", "Sainthili", "Sambalpuri",
                            "Sanskrit", "Santali", "Sinhala", "Sindhi", "Tamil", "Telegu",
                            "Tibetan", "Turkish", "Urdu",
                          ].map((language) => (
                            <MenuItem key={language} value={language}>
                              {language}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth size="small" variant="outlined">
                        <InputLabel required>Genre</InputLabel>
                        <Select
                          id="gener"
                          name="gener"
                          value={formik.values.gener}
                          onChange={formik.handleChange}
                          error={formik.touched.gener && Boolean(formik.errors.gener)}
                          label="Genre"
                        >
                          <MenuItem value="">--Select One--</MenuItem>
                          {[
                            "Film", "Devotional", "Traditional", "Indie Pop", "Instrumental",
                            "Western Classical", "Carnatic Classical", "Hindustani Classical",
                            "Spiritual", "English Pop", "Ghazal", "Regional Pop", "Lounge",
                            "Fusion", "Folk", "Electronic", "HipHop", "Rap", "Rock", "Blues",
                            "Metal", "Jazz",
                          ].map((genre) => (
                            <MenuItem key={genre} value={genre}>
                              {genre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth size="small" variant="outlined">
                        <InputLabel required>Sub Genre</InputLabel>
                        <Select
                          id="sub_gener"
                          name="sub_gener"
                          value={formik.values.sub_gener}
                          onChange={formik.handleChange}
                          error={formik.touched.sub_gener && Boolean(formik.errors.sub_gener)}
                          label="Sub Genre"
                        >
                          <MenuItem value="">--Select One--</MenuItem>
                          {[
                            "Acoustic",
                            "Alternative",
                            "Baul Geet",
                            "Bhajan",
                            "Bihu",
                            "Borgeet",
                            "Chalisa",
                            "Chhattisgarhi",
                            "Contemporary",
                            "DJ Mix",
                            "Dogri",
                            "Experimental",
                            "Geet",
                            "Geeti",
                            "Instrumental",
                            "Indie Folk",
                            "Kashmiri",
                            "Karwa Chaut Songs",
                            "Khasi",
                            "Kirtan",
                            "Kokborok",
                            "Kumauni",
                            "Langa Geet",
                            "LoFi",
                            "Mandarin",
                            "Mapilla",
                            "Mantra",
                            "Marriage Song",
                            "Marwari",
                            "Nazrul Geeti",
                            "Naat",
                            "Naga",
                            "Nepali",
                            "Persian",
                            "Pali",
                            "Progressive",
                            "Psychedelic",
                            "Qawwals",
                            "Rabindra Sangeet",
                            "Rajanikanta",
                            "Raksha Bandhan",
                            "Soundtrack",
                            "Sinhala",
                            "Sindhi",
                            "Traditional",
                            "Tibetan",
                            "Turkish"
                          ].map((genre) => (
                            <MenuItem key={genre} value={genre}>
                              {genre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth size="small" variant="outlined">
                        <InputLabel required>Mood</InputLabel>
                        <Select
                          id="mood"
                          name="mood"
                          value={formik.values.mood}
                          onChange={formik.handleChange}
                          error={formik.touched.mood && Boolean(formik.errors.mood)}
                          label="Mood"
                        >
                          <MenuItem value="">--Select One--</MenuItem>
                          {[
                            "Romantic", "Happy", "Sad", "Dance", "Bhangra", "Patriotic",
                            "Nostalgic", "Inspirational", "Enthusiastic", "Optimistic",
                            "Passion", "Pessimistic", "Spiritual", "Peppy", "Philosophical",
                            "Mellow", "Calm"
                          ].map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Description"
                        required
                        fullWidth
                        id="song_desc"
                        name="song_desc"
                        variant="outlined"
                        size="small"
                        value={formik.values.song_desc}
                        onChange={formik.handleChange}
                        error={formik.touched.song_desc && Boolean(formik.errors.song_desc)}
                        helperText={formik.touched.song_desc && formik.errors.song_desc}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Singer"
                        required
                        fullWidth
                        id="singer"
                        name="singer"
                        variant="outlined"
                        size="small"
                        value={formik.values.singer}
                        onChange={formik.handleChange}
                        error={formik.touched.singer && Boolean(formik.errors.singer)}
                        helperText={formik.touched.singer && formik.errors.singer}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Composer"
                        required
                        fullWidth
                        id="composer"
                        name="composer"
                        variant="outlined"
                        size="small"
                        value={formik.values.composer}
                        onChange={formik.handleChange}
                        error={formik.touched.composer && Boolean(formik.errors.composer)}
                        helperText={formik.touched.composer && formik.errors.composer}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Lyricist"
                        required
                        fullWidth
                        id="lyricist"
                        name="lyricist"
                        variant="outlined"
                        size="small"
                        value={formik.values.lyricist}
                        onChange={formik.handleChange}
                        error={formik.touched.lyricist && Boolean(formik.errors.lyricist)}
                        helperText={formik.touched.lyricist && formik.errors.lyricist}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Director"
                        required
                        fullWidth
                        id="director"
                        name="director"
                        variant="outlined"
                        size="small"
                        value={formik.values.director}
                        onChange={formik.handleChange}
                        error={formik.touched.director && Boolean(formik.errors.director)}
                        helperText={formik.touched.director && formik.errors.director}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Star Cast"
                        required
                        fullWidth
                        id="star_cast"
                        name="star_cast"
                        variant="outlined"
                        size="small"
                        value={formik.values.star_cast}
                        onChange={formik.handleChange}
                        error={formik.touched.star_cast && Boolean(formik.errors.star_cast)}
                        helperText={formik.touched.star_cast && formik.errors.star_cast}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Star Cast"
                        required
                        fullWidth
                        id="star_cast"
                        name="star_cast"
                        variant="outlined"
                        size="small"
                        value={formik.values.star_cast}
                        onChange={formik.handleChange}
                        error={formik.touched.star_cast && Boolean(formik.errors.star_cast)}
                        helperText={formik.touched.star_cast && formik.errors.star_cast}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="CRBT Start Time"
                        required
                        fullWidth
                        id="crbt_start"
                        name="crbt_start"
                        variant="outlined"
                        size="small"
                        value={formik.values.crbt_start}
                        onChange={formik.handleChange}
                        error={formik.touched.crbt_start && Boolean(formik.errors.crbt_start)}
                        helperText={formik.touched.crbt_start && formik.errors.crbt_start}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="CRBT Name"
                        required
                        fullWidth
                        id="crbt_name"
                        name="crbt_name"
                        variant="outlined"
                        size="small"
                        value={formik.values.crbt_name}
                        onChange={formik.handleChange}
                        error={formik.touched.crbt_name && Boolean(formik.errors.crbt_name)}
                        helperText={formik.touched.crbt_name && formik.errors.crbt_name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Wav File"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        type="file"
                        size="small"
                        onChange={(e) => {
                          formik.setFieldValue("wav_file", e.target.files[0]);
                        }}
                        error={formik.touched.wav_file && Boolean(formik.errors.wav_file)}
                        helperText={formik.touched.wav_file && formik.errors.wav_file}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Upload NOC"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="noc_doc"
                        type="file"
                        size="small"
                        onChange={(e) => {
                          formik.setFieldValue("noc_doc", e.target.files[0]);
                        }}
                        error={formik.touched.noc_doc && Boolean(formik.errors.noc_doc)}
                        helperText={formik.touched.noc_doc && formik.errors.noc_doc}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "right" }}>
                      <Button
                        // variant="contained"
                        color="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          handleBack()
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          formik.handleSubmit();
                          scrollTop();
                        }}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>

              </Card>
            </>)
          }

          {activeStep === 2 && (
            <>
              {final.length !== 0 && (
                <Card className="shadow" sx={{ padding: 3 }}>
                  <CardContent>
                    <Typography variant="h5">Uploaded Songs</Typography>
                    <Box mt={2}>
                      {/* Submission status */}
                      {final.map((data, index) => (
                        <SongItemCard
                          field={data.song_title}
                          value={data.singer}
                          icon={<MusicNoteRoundedIcon />}
                        />
                      ))}
                    </Box>

                    {/* Buttons */}
                    <Grid container spacing={2} justifyContent="center" mt={5}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={handleBack}
                          disabled={btnDisabled}
                        >
                          Add Another Song
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={handleFinalSubmit}
                          disabled={btnDisabled}
                        >
                          Final Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </>
          )}

        </div>
      </form>
    </div>
  );
}

export default UploadForm;
