import { AppBar, Avatar, Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import React from 'react';
import {MenuOutlined} from '@mui/icons-material';
import UserCard from './usercard';
import LOGO from "../../../asset/logo_main.png";



export default function TAppBar({handleDrawerToggle}) {
    const isMobile = useMediaQuery("(max-width:600px)");

    const styles = {
        navRoot: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
        },
        avater: {
            backgroundColor: "primary.main",
            width: 35,
            height: 35,
        },
        logoContainer : {
            marginLeft : isMobile ? 0 : 6,
            display : "flex",
            flexDirection : "row",
            justifyContent : "center",
            alignItems : "center",
        }
    }

    return (
        <AppBar
            position="fixed"
            elevation={0}
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                transition: 'width 0.3s ease-in-out',
                backgroundColor: 'background',
                backdropFilter: 'blur(10px)',
                color: 'text.primary',
                borderBottom: 1,
                borderColor: 'border.main',
            }}
        >
            <Box px={2} py={1} display={"felx"} sx={styles.navRoot}>
                <Box sx={styles.logoContainer}>
                    <img src={LOGO} alt="logo" height={40} width={"auto"} />
                </Box>
                <UserCard />
            </Box>
        </AppBar>
    )
}


