import { Box, CircularProgress, TablePagination, } from '@mui/material'
import React, { useState } from 'react'
import AlbumCardItem from './AlbumCardItem'
import AppModal from '../../common/AppModal';
import SongModalView from './SongModalView';


export default function AlbumCardsView({
    data,
    loading,
}) {
    const [modal,setModal] = useState({
        open : false,
        data : {}
    });


    return (

        <Box sx={{
            maxWidth: "100%",
            borderRadius: "5px",
            height: 'calc(100vh - 250px)',
            overflowY: 'auto',
            position: 'relative',
            pt: 2,
            border: "1px solid #4A4A4A",
        }}>

            {
                data?.map((item, index) => (
                    <AlbumCardItem item={item} setModal={setModal} />
                ))
            }
            {
                loading && <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <CircularProgress color="primary" />
                </Box>
            }

            {
                modal.open && (
                    <AppModal
                    width={"40%"}
                    open={modal.open}
                    modalTitle="Song Details"
                    onClose={()=>{setModal({
                        open : false,
                        data : {}
                    })}}
                    >
                        <SongModalView data={modal.data}/>
                    </AppModal>
                )
            }

        </Box>
    )
}


const styles = {
    pagination: {
        position: 'sticky',
        bottom: -1,
        right: 0,
        backgroundColor: 'white.main',
        borderTop: '1px solid #e0e0e0',
    }
}