import { AlbumRounded, ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionSummary,
    Box,
    Avatar,
    Stack,
    AccordionDetails,
    Grid,

} from '@mui/material';
import React from 'react'
import ViewFiled from '../../common/ViewFiled';
import SongItemCard from './SongItemCard';
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded';

export default function AlbumCardItem({ item, setModal }) {
    return (
        <Accordion defaultExpanded={false} >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Box sx={styles.summary_root}>
                    <Avatar sx={{ backgroundColor: "primary.light" }}>
                        <AlbumRounded color="primary" />
                    </Avatar>
                </Box>
                <Stack direction="row" spacing={4}>
                    <ViewFiled field={"Album"} value={item.title} />
                    <ViewFiled field={"Status"} value={item.status === "LABEL_PENDING" ? "PENDING" : item.status} />
                    <ViewFiled field={"Release Date"} value={item.release_date} />
                </Stack>

            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2} direction={"row"}>
                    {
                        item.all_song.length > 0 && item.all_song.map((song) => (
                            <Grid item xs={12} sm={6} md={4} onClick={()=>{setModal({open : true, data : song})}} >
                                <SongItemCard
                                    icon={<MusicNoteRoundedIcon />}
                                    field={song.song_title}
                                    value={song.singer}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}


const styles = {
    summary_root: {
        py: "5px",
        paddingRight: "20px",
        paddingLeft: "10px",
    },
    icon: {
        backgroundColor: "primary.light",
        borderRadius: "10px",
    },
};
