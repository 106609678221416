import { Box, Card, CardHeader, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ScreenToolbar from '../common/ScreenToolbar'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import AlbumCardsView from '../comp/album/AlbumCardView'

export default function AlbumScreen() {
  const nav = useNavigate();
  const [data,setData] = useState([]);


  useEffect(()=>{
    (async()=>{
      const token = localStorage.getItem("token");
      const res = await axios({
        url : `${process.env.REACT_APP_API_BASE}/get_all_album`,
        method : "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(res.data.data)
    })()
  },[])

  return (
    <Box>
      <ScreenToolbar
        leftComps={<Typography variant="title" >Album Records</Typography>}
        rightComps={<>
          <button className='sks-btn' onClick={()=>{nav("/app/upload")}} >Submit New ALbum</button>
        </>}
      />
      <Card>
        <CardHeader />
        <AlbumCardsView
          count={0}
          handlePage
          data={data}
          loading={false}
        />
      </Card>
    </Box>
  )
}
