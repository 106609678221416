import { createTheme } from "@mui/material";

export const mainTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "rgba(47, 203, 110, 1)",
      light: "#e3f2fd",
    },
    secondary: {
      main: "rgba(255, 248, 83, 1)",
      light: "#061023a3",
    },
    success: {
      main: "#22c55e",
      light: "#bbf7d0",
      extlight: "#c7ffc9",
      dark : "#22c55e"
    },
    warning: {
      main: "#ff9800",
      light: "#ffb74d",
      extlight: "#ffedb8",
    },
    error: {
      main: "#f44336",
      light: "#e57373",
      extlight: "#ffcdcd",
    },
    info: {
      main: "#2196f3",
      light: "#64b5f6",
      extlight: "#90caf9",
    },
    background: {
      default: "#252525",
      dark: "#021407",
      // main: "#1976d2",
      light: "#e3f2fd",
      extlight : "#f5f9fc"
    },
    white: {
      main: "#ffffff",
    },
    text: {
      primary: "rgba(47, 203, 110, 1)",
      secondary: "#F2F2F2",
      g1 : "#494949"
    },
    border: {
      main: "#0000001f",
      light: "#F2F2F2",
      // main: "#1976d2",
      // light: "#e3f2fd",
    },
    gray: {
      main: "#f7f7f7",
      dark : "#939393"
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color : "white"
        },
        title: {
          fontSize: "1.3rem",
          fontWeight: 500,
        },
        subtitle1: {
          fontSize: "0.9rem",
          fontWeight: 300,
        },
        cardText1: {
          fontSize: "1rem",
          fontWeight: 200,
        },
        cardText2: {
          fontSize: "1.5rem",
          fontWeight: 500,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          // boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
          boxShadow: "none",
          background: "linear-gradient(115.11deg, rgba(37, 37, 37, 0.275) 38.12%, rgba(139, 139, 139, 0.5) 195.25%)",
          border: "1px solid #4A4A4A",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          background: "linear-gradient(115.11deg, rgba(37, 37, 37, 0.275) 38.12%, rgba(139, 139, 139, 0.5) 195.25%)",
          "& .MuiCardHeader-title": {
            fontSize: "1rem",
            fontWeight: 500,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          // borderRadius: '5px',
          border: "1px solid #4A4A4A",
        },
      },
    },
  },
  shadows: {
    0: "none",
    1: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
    2: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;",
    3: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
    4: "0px 4px 6px rgba(0, 0, 0, 0.16)",
    5: "0px 4px 6px rgba(0, 0, 0, 0.20)",
    8: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;",
    16: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    24: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  typography: {
    fontFamily: 'Poppins, "sans-serif"',
  },
});
