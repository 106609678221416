import { Box, CssBaseline } from '@mui/material'
import React, { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import Sidebar from './sidebar';
import ContentWrapper from './content-wrapper';
import { ThemeProvider } from "@mui/material";
import { mainTheme } from '../../../config/theme';
import TAppBar from './appbar';

const drawerWidth = 70;
const expandedDrawerWidth = 240;


export default function Layout() {

  const [mobileOpen, setMobileOpen] = useState(false);
  const [hover, setHover] = useState(true);


  const handleDrawerToggle = () => {
    // setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Box sx={{ display: 'flex' }}>
        <TAppBar/>
        <Sidebar
          drawerWidth={drawerWidth}
          expandedDrawerWidth={expandedDrawerWidth}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          hover={hover}
          setHover={setHover}
        />
        <ContentWrapper
          hover={hover}
          expandedDrawerWidth={expandedDrawerWidth}
          drawerWidth={drawerWidth}
          sx={{ scrollBehavior: "smooth" }}
        >
          <Outlet />
        </ContentWrapper>
      </Box>
    </ThemeProvider>
  )
}
